//local
// export const POLL_API_URL = "http://localhost:3001";
// export const RESPONSE_API_URL = 'http://localhost:3002';
// export const USER_API_URL = "http://localhost:3003";
// export const COURSE_API_URL = "http://localhost:3004";
// export const INSTITUTION_API_URL = "http://localhost:3005";
// export const AUTHENTICATION_API_URL = "https://staging-dev.localhost.yuja.com:8006";
// export const PUBLIC_ACCESS_API_URL = "http://localhost:3007";
// export const INTEGRATION_API_URL = "https://staging-dev.localhost.yuja.com:8000";
// export const WSS_BASE_URL = "wss://4u76vo63pe.execute-api.us-west-2.amazonaws.com/Prod";
// export const S3_PUBLIC_BUCKET_API_URL = "https://wswy43iwjg.execute-api.us-west-2.amazonaws.com/stage";
// export const S3_PRIVATE_BUCKET_API_URL = "https://o10wfc3nfi.execute-api.us-west-2.amazonaws.com/stage";
// export const S3_PRIVATE_BUCKET_NAME = "yuja-engage-private";
// export const HOST_URL = "http://staging-dev.localhost:3000";
// export const MGMT_PORTAL_URL = "https://main.d2s9k72hat97p8.amplifyapp.com";

//dev
// export const POLL_API_URL = "https://6a4js28b5m.execute-api.us-west-2.amazonaws.com/stage";
// export const RESPONSE_API_URL = "https://p7d4rymhl2.execute-api.us-west-2.amazonaws.com/stage";
// export const USER_API_URL = "https://nbi29d1t48.execute-api.us-west-2.amazonaws.com/stage";
// export const COURSE_API_URL = "https://rz33dvntw1.execute-api.us-west-2.amazonaws.com/stage";
// export const INSTITUTION_API_URL = "https://0sr78spd9k.execute-api.us-west-2.amazonaws.com/stage";
// export const AUTHENTICATION_API_URL = "https://s08wj63zlk.execute-api.us-west-2.amazonaws.com/stage";
// export const PUBLIC_ACCESS_API_URL = "https://y8rrhscamg.execute-api.us-west-2.amazonaws.com/stage";
// export const WSS_BASE_URL = "wss://cvspacf27h.execute-api.us-west-2.amazonaws.com/Prod";
// export const S3_PUBLIC_BUCKET_API_URL = "https://wswy43iwjg.execute-api.us-west-2.amazonaws.com/stage";
// export const S3_PUBLIC_BUCKET_NAME = "yuja-engage-public";
// export const S3_PRIVATE_BUCKET_API_URL = "https://rbcpxl9bf6.execute-api.us-west-2.amazonaws.com/stage";
// export const S3_PRIVATE_BUCKET_NAME = "engage-staging-poll-uw2";
// export const HOST_URL = "engage.yuja.com";
// export const PPT_MGMT_PORTAL_URL = "https://qa.d1eg472nfacrjh.amplifyapp.com";

// qa
export const POLL_API_URL = "https://z3i8506xf7.execute-api.ca-central-1.amazonaws.com/stage";
export const RESPONSE_API_URL = "https://d9d6j8x2p4.execute-api.ca-central-1.amazonaws.com/stage";
export const USER_API_URL = "https://jqdw4m8lq4.execute-api.ca-central-1.amazonaws.com/stage";
export const COURSE_API_URL = " https://b3vtulttkg.execute-api.ca-central-1.amazonaws.com/stage";
export const INSTITUTION_API_URL = "https://28pxmqx5ui.execute-api.ca-central-1.amazonaws.com/stage";
export const AUTHENTICATION_API_URL = "https://i09f0qmst5.execute-api.ca-central-1.amazonaws.com/stage";
export const PUBLIC_ACCESS_API_URL = "https://q67lrk52bd.execute-api.ca-central-1.amazonaws.com/stage";
export const INTEGRATION_API_URL = "https://iqs0zpwsue.execute-api.ca-central-1.amazonaws.com/stage";
export const WSS_BASE_URL = "wss://14f4qsz1ae.execute-api.ca-central-1.amazonaws.com/Prod";
export const S3_PUBLIC_BUCKET_API_URL = "https://sy0n2l00ca.execute-api.ca-central-1.amazonaws.com/stage";
export const S3_PUBLIC_BUCKET_NAME = "qa-public-access-s3-bucket";
export const S3_PRIVATE_BUCKET_API_URL = " https://98gknfvgm6.execute-api.ca-central-1.amazonaws.com/stage";
export const S3_PRIVATE_BUCKET_NAME = "engage-qa-poll-ca1";
export const HOST_URL = "engage.yuja.com";
export const PPT_MGMT_PORTAL_URL = "https://qa.d1eg472nfacrjh.amplifyapp.com";
export const REPORT_API_URL = 'https://pu9z77uykk.execute-api.ca-central-1.amazonaws.com/stage';

// websockets with NODE
// export const WSS_BASE_URL = "wss://t7ky990pqh.execute-api.us-west-2.amazonaws.com/dev"

// prod-uw2
// export const POLL_API_URL = "https://smuir4z2m3.execute-api.us-west-2.amazonaws.com/stage";
// export const RESPONSE_API_URL = "https://rnrz9jvima.execute-api.us-west-2.amazonaws.com/stage";
// export const USER_API_URL = "https://rmzd82gexi.execute-api.us-west-2.amazonaws.com/stage";
// export const COURSE_API_URL = "https://3dfvqptoe8.execute-api.us-west-2.amazonaws.com/stage";
// export const INSTITUTION_API_URL = "https://kn8l2x6jc6.execute-api.us-west-2.amazonaws.com/stage";
// export const AUTHENTICATION_API_URL = "https://ewnysu12m8.execute-api.us-west-2.amazonaws.com/stage";
// export const PUBLIC_ACCESS_API_URL = "https://y7a4wve2sj.execute-api.us-west-2.amazonaws.com/stage";
// export const REPORT_API_URL = "https://fmc54yui0c.execute-api.us-west-2.amazonaws.com/stage";
// export const WSS_BASE_URL = "wss://eqdhd4m2nf.execute-api.us-west-2.amazonaws.com/Prod";
// export const S3_PUBLIC_BUCKET_API_URL = "https://zlq2wlfsme.execute-api.us-west-2.amazonaws.com/stage";
// export const S3_PUBLIC_BUCKET_NAME = "yuja-engage-public";
// export const S3_PRIVATE_BUCKET_API_URL = "https://d9vu35sjv8.execute-api.us-west-2.amazonaws.com/stage";
// export const S3_PRIVATE_BUCKET_NAME = "engage-prod-poll-uw2";
// export const HOST_URL = "engage.yuja.com";
// export const PPT_MGMT_PORTAL_URL = "https://prod.d2xzaiuv1non7b.amplifyapp.com/";

// prod-ca1
// export const POLL_API_URL = "https://k45d5ajmtk.execute-api.ca-central-1.amazonaws.com/stage";
// export const RESPONSE_API_URL = "https://qwnoslac3d.execute-api.ca-central-1.amazonaws.com/stage";
// export const USER_API_URL = "https://8za3ujw0l5.execute-api.ca-central-1.amazonaws.com/stage";
// export const COURSE_API_URL = "https://q80x338y06.execute-api.ca-central-1.amazonaws.com/stage";
// export const INSTITUTION_API_URL = "https://rthut6z7m9.execute-api.ca-central-1.amazonaws.com/stage";
// export const INTEGRATION_API_URL = "https://zv99v1wgii.execute-api.ca-central-1.amazonaws.com/stage";
// export const AUTHENTICATION_API_URL = "https://t0bzqnycc6.execute-api.ca-central-1.amazonaws.com/stage";
// export const PUBLIC_ACCESS_API_URL = "https://o066hzlq4d.execute-api.ca-central-1.amazonaws.com/stage";
// export const REPORT_API_URL = "https://fmc54yui0c.execute-api.us-west-2.amazonaws.com/stage";
// export const WSS_BASE_URL = "wss://l8shnw6bqk.execute-api.ca-central-1.amazonaws.com/Prod";
// export const S3_PUBLIC_BUCKET_API_URL = "https://bptq7wmimg.execute-api.ca-central-1.amazonaws.com/stage";
// export const S3_PUBLIC_BUCKET_NAME = "yuja-engage-public";
// export const S3_PRIVATE_BUCKET_API_URL = "https://nn677efkp7.execute-api.ca-central-1.amazonaws.com/stage";
// export const S3_PRIVATE_BUCKET_NAME = "engage-prod-poll-ca1";
// export const HOST_URL = "engage.yuja.com";
// export const PPT_MGMT_PORTAL_URL = "https://prod.d2xzaiuv1non7b.amplifyapp.com/";
